import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useModalStore = defineStore('modalStore', () => {
  const termsModalOpen = ref(false);
  const privacyModalOpen = ref(false);
  const contractModalOpen = ref(false);

  return {
    termsModalOpen,
    privacyModalOpen,
    contractModalOpen,
  };
});
