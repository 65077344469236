import { ref } from 'vue';
import { defineStore } from 'pinia';
import { newsType } from '@/types/postTypes';
import { http } from '@/core/api';

export const useNewsStore = defineStore('newsStore', () => {
  const page = ref<number>(1);
  const loading = ref<boolean>(false);
  const news = ref<newsType[]>([]);
  const selectedPost = ref<newsType | null>(null);

  function setPost(selected: newsType) {
    selectedPost.value = selected;
  }

  async function getNewsList() {
    loading.value = true;
    page.value = 1;
    try {
      const { data } = await http.get('news/all?page=1&limit=4');

      news.value = data.body.result;
    } catch (err) {
      console.log(err);
    } finally {
      loading.value = false;
    }
  }

  async function getNewsById(id: number) {
    loading.value = true;
    try {
      const { data } = await http.get(`news/${id}`);

      selectedPost.value = data.body;
    } catch (err) {
      console.log(err);
    } finally {
      loading.value = false;
    }
  }

  async function getMoreNews() {
    loading.value = true;
    page.value++;
    try {
      const { data } = await http.get(`news/all?page=${page.value}&limit=4`);

      news.value.push(data.body.result);
      news.value = news.value.flat();
    } catch (err) {
      page.value--;
      console.log(err);
    } finally {
      loading.value = false;
    }
  }

  return {
    page,
    loading,
    news,
    selectedPost,
    setPost,
    getNewsList,
    getNewsById,
    getMoreNews,
  };
});
