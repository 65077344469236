import { setBearerToken } from '@/core/api/index';
import { userI } from '@/types/userTypes';
// TOKEN
export const setHeaderToken = (token: string) => setBearerToken(token);
export const getAccessToken = () => sessionStorage.getItem('token');
export const deleteAccessToken = () => sessionStorage.removeItem('token');
export const setAccessToken = (token: string) => sessionStorage.setItem('token', token);
export const setUserHubToken = (token: string) => sessionStorage.setItem('hub-token', token);
export const getUserHubToken = () => sessionStorage.getItem('hub-token');
export const deleteHubToken = () => sessionStorage.removeItem('hub-token');

// REFRESH TOKEN
export const setRefreshToken = (token: string) => sessionStorage.setItem('refresh-token', token);
export const getRefreshToken = () => sessionStorage.getItem('refresh-token');
export const deleteRefreshToken = () => sessionStorage.removeItem('refresh-token');

// USER
export const getLocalUser = () => JSON.parse(sessionStorage.getItem('user') as string);
export const deleteLocalUser = () => sessionStorage.removeItem('user');
export const deleteLocalPlan = () => sessionStorage.removeItem('plan');
export const setLocalUser = (User: userI) => sessionStorage.setItem('user', JSON.stringify(User));
