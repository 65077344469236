import { defineStore } from 'pinia';

interface State {
  loading: boolean;
}

export const preloaderStore = defineStore('preloaderStore', {
  state: () =>
    ({
      loading: false,
    } as State),
  actions: {
    setPreloader(loading: boolean) {
      this.loading = loading;
    },
  },
});
