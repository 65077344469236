import { ref } from 'vue';
import { defineStore } from 'pinia';
import { DepoimentoType } from '@/types/cardTypes';
import { http } from '@/core/api';

export const useDepoStore = defineStore('depoStore', () => {
  const loading = ref<boolean>(false);
  const depoimentos = ref<DepoimentoType[]>([]);

  async function getTestimonials() {
    loading.value = true;
    try {
      const { data } = await http.get('/testimonials/all');

      depoimentos.value = data.body.result;
    } catch (err) {
      console.error(err);
    }
    loading.value = false;
  }

  return {
    loading,
    depoimentos,
    getTestimonials,
  };
});
