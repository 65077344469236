import { ref } from 'vue';
import { defineStore } from 'pinia';
import { postType, categorieType } from '@/types/postTypes';
import { http } from '@/core/api';

export const useBlogStore = defineStore('blogStore', () => {
  const page = ref<number>(1);
  const loading = ref<boolean>(false);
  const posts = ref<postType[]>([]);
  const catId = ref<number>();
  const categories = ref<categorieType[]>([]);
  const selectedPost = ref<postType | null>(null);

  function setPost(selected: postType) {
    selectedPost.value = selected;
  }

  async function getPostList() {
    loading.value = true;
    page.value = 1;
    try {
      const { data } = await http.get(`posts/all?page=1&limit=4${catId.value ? `&categoryId=${catId.value}` : ''}`);

      posts.value = data.body.result;
    } catch (err) {
      console.log(err);
    } finally {
      loading.value = false;
    }
  }

  async function getCategorieList() {
    loading.value = true;
    try {
      const { data } = await http.get('categories/all?status=PUBLISHED');

      categories.value = data.body.result;
    } catch (err) {
      console.log(err);
    } finally {
      loading.value = false;
    }
  }

  async function getPostById(id: number) {
    loading.value = true;
    try {
      const { data } = await http.get(`posts/${id}`);

      selectedPost.value = data.body;
    } catch (err) {
      console.log(err);
    } finally {
      loading.value = false;
    }
  }

  async function getMorePosts() {
    loading.value = true;
    page.value++;
    try {
      const { data } = await http.get(
        `posts/all?page=${page.value}&limit=4${catId.value ? `&categoryId=${catId.value}` : ''}`
      );

      posts.value.push(data.body.result);
      posts.value = posts.value.flat();
    } catch (err) {
      page.value--;
      console.log(err);
    } finally {
      loading.value = false;
    }
  }

  return {
    page,
    loading,
    posts,
    catId,
    categories,
    selectedPost,
    setPost,
    getPostById,
    getPostList,
    getMorePosts,
    getCategorieList,
  };
});
