export const RoutesEnum = {
  1: '/trabalhista-service',
  2: '/gestor-service',
  3: '/pergunte-ao-especialista',
  7: '/mentoria-service',
  8: '/buscador-service',
  9: '/pdf-service',
  10: '/assinador-service',
  11: '/contratualista-service',
};
