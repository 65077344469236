<script setup ts>
import { preloaderStore } from '@/store/preloaderStore';
const preloader = preloaderStore();
</script>

<template>
  <div v-if="preloader.loading" class="preloader d-flex align-items-center justify-content-center">
    <p>Carregando...</p>
  </div>
</template>

<style lang="scss" scoped>
.preloader {
  position: fixed;
  top: 0;
  background: #fffd38;

  width: 100vw;
  height: 100%;
  z-index: 1149;
}
.preloader img {
  width: 200px;
  animation: load 0.5s alternate-reverse infinite;
  opacity: 50%;
}

@keyframes load {
  50% {
    opacity: 50%;
  }
  100% {
    opacity: 100%;
  }
}
</style>
