import { createApp } from 'vue';
import { createPinia } from 'pinia';
import router from './router';
import { createMetaManager } from 'vue-meta';
import { vMaska } from 'maska';
import VCalendar from 'v-calendar';
import App from './App.vue';
import money from 'v-money3';
import vSelect from 'vue-select';
import VueClickAway from 'vue3-click-away';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faMinus, faPlus, faCircleUp, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue3TouchEvents from 'vue3-touch-events';
import VueSplide from '@splidejs/vue-splide';

import 'notyf/notyf.min.css';
import 'v-calendar/dist/style.css';
import './assets/css/global.css';
import 'vue-select/dist/vue-select.css';
import 'vue3-carousel/dist/carousel.css';
import '@splidejs/vue-splide/css';
import '@splidejs/vue-splide/css/core';
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const AppVue = createApp(App);

library.add(faBars, faMinus, faPlus, faCircleUp, faCircleCheck, faCircleXmark);

AppVue.use(createPinia());
AppVue.use(router);
AppVue.directive('maska', vMaska);
AppVue.use(money);
AppVue.use(VCalendar);
AppVue.use(VueClickAway);
AppVue.use(Vue3TouchEvents);
AppVue.use(createMetaManager());
AppVue.component('v-select', vSelect);
AppVue.component('font-awesome-icon', FontAwesomeIcon);
AppVue.use(VueSplide);
AppVue.mount('#app');
