import { defineStore } from 'pinia';
import { planType } from '@/types/cardTypes';
import { ref } from 'vue';
import { http } from '@/core/api';

export const useServicePlansStore = defineStore('servicePlans', () => {
  const loading = ref<boolean>(false);
  const plans = ref<planType[]>([]);
  const selectedPlan = ref<planType | null>(null);
  const activePer = ref<number>(12);

  function setSelected(plan: planType) {
    selectedPlan.value = plan;
  }

  async function getPlans() {
    loading.value = true;
    try {
      const { data } = await http.get('plans/all');

      plans.value = data.body.result;

      return data.body.result;
    } catch (err) {
      console.error(err);
    } finally {
      loading.value = false;
    }
  }

  return {
    loading,
    activePer,
    plans,
    selectedPlan,
    setSelected,
    getPlans,
  };
});
