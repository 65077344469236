import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomePage from '../pages/home/Index.vue';
import AdvServicePage from '../pages/advService/Index.vue';
import TrabalhistaServicePage from '../pages/trabalhistaService/Index.vue';
import GestorServicePage from '../pages/gestorService/Index.vue';
import BuscadorServicePage from '../pages/buscadorService/Index.vue';
import PergunteServicePage from '../pages/pergunteService/Index.vue';
import MentoriaServicePage from '../pages/mentoriaService/Index.vue';
import PdfServicePage from '../pages/pdfService/Index.vue';
import AssinadorServicePage from '../pages/assinadorService/Index.vue';
import ContratualistaServicePage from '../pages/contratualistaService/Index.vue';
import PlanosPage from '../pages/planos/Index.vue';
import PlanosCadastro from '../pages/planos/PlanosCadastro.vue';
import NewsPage from '../pages/news/Index.vue';
import NewsView from '../pages/news/view.vue';
import NewsPostVue from '@/pages/news/NewsPost.vue';
import BlogPage from '../pages/blog/Index.vue';
import BlogPostVue from '@/pages/blog/BlogPost.vue';
import BlogView from '../pages/blog/view.vue';
import Login from '../pages/login/Login.vue';
import CadastroVue from '@/pages/login/Cadastro.vue';
import PassRecover from '@/pages/login/PassRecover.vue';
import ContatoPage from '../pages/contato/index.vue';
import PassChangeVue from '@/pages/login/PassChange.vue';
import Confirmado from '@/pages/planos/Confirmado.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/adv-service',
    name: 'ADVService',
    component: AdvServicePage,
  },
  {
    path: '/trabalhista-service',
    name: 'TrabalhistaService',
    component: TrabalhistaServicePage,
  },
  {
    path: '/gestor-service',
    name: 'GestorService',
    component: GestorServicePage,
  },
  {
    path: '/buscador-service',
    name: 'BuscadorService',
    component: BuscadorServicePage,
  },
  {
    path: '/pergunte-ao-especialista',
    name: 'PergunteService',
    component: PergunteServicePage,
  },
  {
    path: '/mentoria-service',
    name: 'MentoriaService',
    component: MentoriaServicePage,
  },
  {
    path: '/pdf-service',
    name: 'PdfService',
    component: PdfServicePage,
  },
  {
    path: '/assinador-service',
    name: 'AssinadorService',
    component: AssinadorServicePage,
  },
  {
    path: '/contratualista-service',
    name: 'ContratualistaService',
    component: ContratualistaServicePage,
  },
  {
    path: '/planos',
    children: [
      {
        path: '',
        name: 'Planos',
        component: PlanosPage,
      },
      {
        path: '/planos/cadastro',
        name: 'Cadastro - Planos',
        component: PlanosCadastro,
      },
      {
        path: '/planos/confirmado',
        name: 'Confirmado - Planos',
        component: Confirmado,
      },
    ],
  },
  {
    path: '/noticias',
    component: NewsView,
    children: [
      {
        path: '',
        name: 'Noticias',
        component: NewsPage,
      },
      {
        path: '/noticias/:id/:title',
        name: 'News Post',
        component: NewsPostVue,
      },
    ],
  },
  {
    path: '/blog',
    component: BlogView,
    children: [
      {
        path: '',
        name: 'Blog',
        component: BlogPage,
      },
      {
        path: '/blog/:id/:title',
        name: 'Blog Post',
        component: BlogPostVue,
      },
    ],
  },
  {
    path: '/login',
    children: [
      { path: '', name: 'Login', component: Login },
      { path: '/login/cadastro', name: 'Cadastro', component: CadastroVue },
      { path: '/login/recuperar', name: 'Recuperar Senha', component: PassRecover },
      { path: '/login/novasenha/:token', name: 'Cadastrar nova senha', component: PassChangeVue },
    ],
  },
  {
    path: '/contato',
    name: 'Contato',
    component: ContatoPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
