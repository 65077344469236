import { ref } from 'vue';
import { defineStore } from 'pinia';
import { http } from '@/core/api/index';
import { userI, loginI } from '@/types/userTypes';
import {
  deleteLocalUser,
  deleteRefreshToken,
  getLocalUser,
  setAccessToken,
  // setLocalUser,
  // setRefreshToken,
  setUserHubToken,
  getUserHubToken,
  deleteHubToken,
} from './storage';
import { Notyf } from 'notyf';
const url = process.env.VUE_APP_HUB_URL;

type User = {
  access_token: string;
  refresh_token: string;
  userId: number;
};

export const useUserStore = defineStore('userStore', () => {
  const notyf = new Notyf();
  const hubUrl = process.env.VUE_APP_HUB_URL;

  const loading = ref<boolean>(false);
  const user = ref<userI>({
    userId: 0,
    email: '',
    username: '',
  });

  function bytesToBase64(bytes) {
    const binString = Array.from(bytes, (x: any) => String.fromCodePoint(x)).join('');
    return btoa(binString);
  }

  bytesToBase64(new TextEncoder().encode('a Ā 𐀀 文 🦄'));

  const loginAction = async (loginData: loginI) => {
    loading.value = true;
    try {
      const { data } = await http.post('auth/signin', loginData);

      if (data.statusCode !== 200) {
        throw new Error(data.message);
      }

      // user.value = { username: data.body.username, email: data.body.email, userId: data.body.userId };
      // setLocalUser({ username: data.body.username, email: data.body.email, userId: data.body.userId });
      // setRefreshToken(data.body.refresh_token);
      setAccessToken(data.body.access_token);

      const jsonUser = JSON.stringify(data.body);
      const encodedUser = bytesToBase64(new TextEncoder().encode(jsonUser));

      setUserHubToken(encodedUser);

      if (encodedUser.length > 0) {
        window.location.href = `${url ? url.toString() : 'http://localhost:3000/'}?token=${encodedUser}`;
        console.log(`dev hub: localhost:3000/?token=${encodedUser}`);
      }
    } catch (err: any) {
      notyf.error(err.message);
    } finally {
      loading.value = false;
    }
  };

  const checkUserAction = () => {
    const localUser = getLocalUser();
    if (!user.value.username && localUser !== null) {
      user.value = localUser;
    }
  };

  const base64ToBytes = (base64: any) => {
    // const binString = atob(base64)
    return Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));
  };

  const goToHub = async () => {
    loading.value = true;
    const hubToken = ref<string | null>(getUserHubToken());

    const jsonUser = ref<User>(JSON.parse(new TextDecoder().decode(base64ToBytes(hubToken.value))));

    if (!hubToken.value) logoutAction();

    try {
      const { data } = await http.post('/refresh-token', {
        token: jsonUser.value.refresh_token,
        userId: Number(jsonUser.value.userId),
      });

      if (data.statusCode === 200 || data.statusCode === 201) {
        jsonUser.value.access_token = data.body.access_token;
        jsonUser.value.refresh_token = data.body.refresh_token;

        console.log('jsonUser', jsonUser.value);

        hubToken.value = bytesToBase64(new TextEncoder().encode(JSON.stringify(jsonUser.value)));

        setUserHubToken(hubToken.value);

        window.location.href = `${hubUrl}/?token=${hubToken.value}`;
        console.log(`dev hub: localhost:3000/?token=${hubToken.value}`);
      } else {
        logoutAction();
      }
    } catch (err) {
      console.log(err);
    }

    // if (hubToken.value && hubToken.value.length > 0) {
    //   window.open(`https://hub.advservice.com.br/?token=${hubToken.value}`, '_blank');
    // }

    loading.value = false;
  };

  const logoutAction = () => {
    deleteLocalUser();
    deleteRefreshToken();
    deleteHubToken();
    user.value = {
      userId: 0,
      email: '',
      username: '',
    };
  };

  return {
    loading,
    user,
    loginAction,
    checkUserAction,
    logoutAction,
    goToHub,
  };
});
