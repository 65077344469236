import { ref } from 'vue';
import { defineStore } from 'pinia';
import { FaqProps } from '@/types/cardTypes';
import { http } from '@/core/api';

export const useFaqStore = defineStore('faqStore', () => {
  const loading = ref(false);
  const questions = ref<FaqProps[]>([]);

  async function getQuestions() {
    loading.value = true;

    try {
      const { data } = await http.get('/faq/all');

      questions.value = data.body.result;
    } catch (err) {
      console.log(err);
    } finally {
      loading.value = false;
    }
  }

  return {
    loading,
    questions,
    getQuestions,
  };
});
